import React from 'react';
import './ovpffooter.css';

const Footer = () => (
  <footer className="ovpf-footer">
    <div className="footer1">
      <div className="column50">
        <div className="footer-inner">
          <h4>Office of the Vice Provost for Faculty</h4>
          The Office of the Vice Provost for Faculty facilitates every stage of a faculty member’s
          life at Penn, from appointment through post-retirement. It manages academic
          appointments and promotions; supports initiatives to enhance faculty recruitment,
          mentoring, climate, retention, and leadership; provides resources, policies, and
          information; collaborates with peer institutions on important initiatives
          for faculty; and advances Penn’s commitment to equity, diversity, and inclusion.
        </div>
      </div>
      <div className="column25">
        <div className="footer-inner">
          <h4>Other Penn Offices</h4>
          <ul>
            <li>
              <a href="https://diversity.upenn.edu/">University Diversity Website</a>
            </li>
            <li>
              <a href="https://ombuds.upenn.edu/">Ombuds</a>
            </li>
            <li>
              <a href="https://titleixoffice.upenn.edu/">Title IX Officer</a>
            </li>
            <li>
              <a href="https://www.hr.upenn.edu/">Human Resources</a>
            </li>
            <li>
              <a href="https://faculty.upenn.edu/about#FacAffairs">Faculty Affairs Coordinators by School</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="column25">
        <div className="footer-inner">
          <h4>Contact the OVPF</h4>
          1 College Hall, Suite 119
          <br />
          University of Pennsylvania
          <br />
          Philadelphia, PA
          <br />
          19104-6303 USA
          <br />
          <br />
          TEL: 215-746-4771
          <br />
          EMAIL:
          <a href="mailto:provost-fac@upenn.edu?subject=From the OVPF Website">provost-fac@upenn.edu</a>
          <br />
          <br />
        </div>
      </div>
    </div>
    <div className="footer2">
      &copy; Copyright
      &nbsp;
      {new Date().getFullYear()}
      &nbsp;
      -
      &nbsp;
      <a href="http://www.upenn.edu/">University of Pennsylvania</a>
      &nbsp;-&nbsp;
      <a href="https://provost.upenn.edu/">Provost&apos;s Center</a>
      &nbsp;-&nbsp;
      <a href="https://faculty.upenn.edu/">Office of the Vice Provost for Faculty</a>
      &nbsp;/&nbsp;
      <a href="https://www.upenn.edu/about/privacy_policy">Privacy Policy</a>
      &nbsp;/&nbsp;
      <a href="https://accessibility.web-resources.upenn.edu/get-help" rel="noopener noreferrer">Report accessibility issues &amp; get help</a>
    </div>
  </footer>
);

export default Footer;
